import React from 'react';
import LocationsHero from '../components/LocationsPage/LocationsHero';
import LocationCards from '../components/LocationsPage/LocationCards';
import SEO from '../components/SEO';


export default function LocationsPage()
{
    return(
    <>
        <SEO
            title="Ronin Cannabis Locations"
            description="Ronin Cannabis Locations Page"
        />

        <LocationsHero/>

        <LocationCards/>
    </>
    )
}