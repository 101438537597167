import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    padding-top: 5vh;
    //padding-bottom: 2.5vh;

    background-color: black;

    display: flex;
    flex-direction: column;
    align-items: center;

    .header{
        background-color: rgba(255, 255, 255, 0.1);
        filter: blur(8px);

        height: 5vw;
        width: 100%;

        margin-bottom: 15vh;
    }
`;

export default function LocationsHero()
{
    return(
        <Wrapper>
            <div className="header"></div>

            <h1>Locations</h1>
        </Wrapper>
    )
}