import React from 'react';
import styled from 'styled-components';

import { graphql, useStaticQuery, Link } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';

import { convertToBgImage } from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';

const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    width: 100%;

    padding: 5% 2.5% 10% 2.5%;

    background-color: black;

    .locationcard{
        min-width: 350px;

        width: 32.5%;
        height: 100%;

        border-radius: 10px;
        overflow: hidden;

        display: flex;
        flex-direction: column;
        align-items: center;

        flex: 1;

        padding: 10% 5%;
        margin: 1.5%;

        h2{
            margin-bottom: 3vh;
        }

        .address{
            margin: 2.5vh 0;
            color: white;
            text-align: center;
        }

        .linkbutton{
            margin-top: 4.5vh;
            padding: 10px 60px;
            background: var(--pink);
            box-shadow: 0px 0px 4px #e94799, 0px 0px 25px #e94799;
            cursor: pointer;
            font-family: Space-Age;
            text-align: center;

            color: white;


            &:hover{
                background: white;
                color: var(--pink);
            }
        }
    }

    @media(max-width: 1000px)
    {
        .locationcard{
            .linkbutton{
                padding: 5px 20px;
            }
        }
    }
`;

export default function LocationCards()
{
    const { cambridgeImageFile, guelphImageFile } = useStaticQuery(
        graphql`
            query {
                cambridgeImageFile: file(relativePath: { eq: "cambridge-bg.jpg" }) {
                    childImageSharp {
                    gatsbyImageData(
                        width: 1000
                        quality: 100
                        placeholder: BLURRED
                        formats: [AUTO, WEBP, AVIF]
                    )
                    }
                }
                guelphImageFile: file(relativePath: { eq: "guelph-bg.jpg" }) {
                    childImageSharp {
                    gatsbyImageData(
                        width: 1000
                        quality: 100
                        placeholder: BLURRED
                        formats: [AUTO, WEBP, AVIF]
                    )
                    }
                }
            }
        `
    );

    console.log('images', cambridgeImageFile, guelphImageFile)

    const cambridgeImage = getImage(cambridgeImageFile);
    const guelphImage = getImage(guelphImageFile);

    const bgCambridgeImage = convertToBgImage(cambridgeImage);
    const bgGuelphImage = convertToBgImage(guelphImage);

    return(
        <Wrapper>
            <BackgroundImage
                Tag="div"
                {...bgGuelphImage}
                className="locationcard"
            >
                <h2>Guelph</h2>

                <p className="address">
                    86 Gordon Street, Guelph, ON
                </p>

                <Link className="linkbutton" to="/guelph/livemenu">Shop Location</Link>
            </BackgroundImage>

            <BackgroundImage
                Tag="div"
                {...bgCambridgeImage}
                className="locationcard"
            >
                <h2>Cambridge</h2>

                <p className="address">
                    901 Jamieson Pkwy Cambridge, ON
                </p>

                <Link className="linkbutton" to="/cambridge/livemenu">Shop Location</Link>
            </BackgroundImage>
        </Wrapper>
    )
}